(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch
  require('./modules/target-blank.js');
  require('./modules/slick.js');
  require('./modules/nav-shop.js');
  require('./modules/tab.js');
  require('./modules/popup.js');
  require('./modules/dropdown.js');
  require('./modules/search.js');
  require('./modules/counter.js');
  require('./modules/checkbox.js');
  require('./modules/cart.js');
  require('./modules/filter.js');
  require('./modules/two-blocks.js');
  require('./modules/checkout.js');
  require('./modules/tabs.js');
});
// remove preloader
window.addEventListener('load', function () {
  $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart'
  });
});

},{"./modules/cart.js":2,"./modules/checkbox.js":3,"./modules/checkout.js":4,"./modules/counter.js":5,"./modules/dropdown.js":6,"./modules/filter.js":7,"./modules/nav-shop.js":8,"./modules/popup.js":9,"./modules/search.js":10,"./modules/slick.js":11,"./modules/tab.js":12,"./modules/tabs.js":13,"./modules/target-blank.js":14,"./modules/two-blocks.js":15}],2:[function(require,module,exports){
"use strict";

var btn = document.querySelector('.open-cart');
var body = document.querySelector('.page-wrapper');
var cart = document.querySelector('.cart');
var close = cart.querySelector('.close-btn');
var handleCart = function handleCart(e) {
  cart.classList.toggle('opened');
  body.classList.toggle('opened');
};
btn.addEventListener('click', handleCart, false);
close.addEventListener('click', handleCart, false);

},{}],3:[function(require,module,exports){
// if (document.querySelector('input[type="checkbox"]')) {
//     let checkboxes = document.querySelectorAll('input[type="checkbox"]')
//     checkboxes.forEach(element => {
//         element.addEventListener('click', () => {
//             element.classList.toggle('checked')
//         })
//     });
// }
"use strict";

},{}],4:[function(require,module,exports){
"use strict";

if (document.querySelector('.product')) {
  var checkout = document.querySelector('.checkout');
  var checkoutButton = checkout.querySelector('button');
  var checkoutMobile = document.querySelector('.checkout');
  var checkoutMObileButton = checkoutMobile.querySelector('button');
  checkoutMObileButton.addEventListener('click', function () {
    checkoutButton.click();
  });
}

},{}],5:[function(require,module,exports){
"use strict";

var counters = document.querySelectorAll('.counter');
if (counters.length > 0) {
  counters.forEach(function (counter) {
    var count = counter.querySelector('.count');
    var minus = counter.querySelector('.minus');
    var plus = counter.querySelector('.plus');
    minus.addEventListener('click', function () {
      if (count.value > 1) {
        count.value--;
        console.log(count.value);
      }
    });
    plus.addEventListener('click', function () {
      count.value++;
      console.log(count.value);
    });
  });
}

},{}],6:[function(require,module,exports){
"use strict";

if (document.querySelector('.dropdown')) {
  document.querySelectorAll('.dropdown').forEach(function (dropdownWrapper) {
    var dropdownList = dropdownWrapper.querySelector('.dropdown__list');
    var dropdownItems = dropdownWrapper.querySelectorAll('.dropdown__list-item');
    if (document.querySelector('.dropdown__button')) {
      var dropdownButton = dropdownWrapper.querySelector('.dropdown__button');
      dropdownButton.addEventListener('click', function (e) {
        e.preventDefault();
        if (!dropdownButton.classList.contains('opened')) {
          dropdownButton.classList.add('opened');
          dropdownList.classList.add('dropdown__list--visible');
        } else {
          dropdownButton.classList.remove('opened');
          dropdownList.classList.remove('dropdown__list--visible');
        }
      });
    }
    dropdownItems.forEach(function (element) {
      element.addEventListener('click', function () {
        if (document.querySelector('.product')) {
          var _dropdownButton = dropdownWrapper.querySelector('.dropdown__button');
          _dropdownButton.innerText = element.innerText;
          var input = _dropdownButton.nextElementSibling;
          input.value = element.innerText;
          _dropdownButton.classList.remove('opened');
        }
        if (document.querySelector('.shop-filter')) {
          var _dropdownButton2 = dropdownWrapper.querySelector('.dropdown__button');
          _dropdownButton2.classList.remove('opened');
        }
        if (!dropdownList.classList.contains('no-folding')) {
          dropdownList.classList.remove('dropdown__list--visible');
        }
        if (!dropdownList.classList.contains('multiple-choice')) {
          dropdownItems.forEach(function (el) {
            el.classList.remove('checked');
          });
        }
        if (element.classList.contains('checked')) {
          element.classList.remove('checked');
        } else {
          element.classList.add('checked');
        }
      });
    });
  });
  document.addEventListener('click', function (el) {
    var target = el.target;
    var openedDropdowns = document.querySelectorAll('.dropdown');
    if (!target.closest('.dropdown')) {
      openedDropdowns.forEach(function (element) {
        element.querySelector('.dropdown__button').classList.remove('opened');
        element.querySelector('.dropdown__list').classList.remove('dropdown__list--visible');
      });
    } else if (target.closest('.dropdown')) {
      var openedButton = document.querySelectorAll('.dropdown__button.opened');
      if (openedButton.length > 0) {
        openedButton.forEach(function (element) {
          if (element != target) {
            var dropdown = element.closest('.dropdown');
            var dropdownList = dropdown.querySelector('.dropdown__list');
            element.classList.remove('opened');
            dropdownList.classList.remove('dropdown__list--visible');
          }
        });
      }
    }
  });
}

},{}],7:[function(require,module,exports){
"use strict";

if (document.querySelector('.shop-filter')) {
  var shopFilter = document.querySelector('.shop-filter');
  var filterItems = shopFilter.querySelectorAll('.dropdown__list-item');
  var hiddenForm = document.querySelector('.form-hidden');
  var orderBy = hiddenForm.querySelector('input[name="orderby"]');
  var order = hiddenForm.querySelector('input[name="order"]');
  var size = hiddenForm.querySelector('input[name="size"]');
  var color = hiddenForm.querySelector('input[name="color"]');
  var priceMin = hiddenForm.querySelector('input[name="priceMin"]');
  var priceMax = hiddenForm.querySelector('input[name="priceMax"]');
  var filtersQuantity = document.querySelector('.filters-quantity');
  var colors = [];
  var sizes = [];
  filterItems.forEach(function (element) {
    element.addEventListener('click', function (el) {
      var checkUndef = function checkUndef(data, value) {
        var sizes = [];
        var colors = [];
        if (value !== undefined) {
          if (data == size) {
            var parent = el.target.closest('.dropdown__list');
            var checked = parent.querySelectorAll('.checked');
            if (checked.length > 0) {
              checked.forEach(function (element) {
                sizes.push(element.getAttribute('data-size'));
              });
            }
            data.value = sizes;
          } else if (data == color) {
            var _parent = el.target.closest('.dropdown__list');
            var _checked = _parent.querySelectorAll('.checked');
            if (_checked.length > 0) {
              _checked.forEach(function (element) {
                colors.push(element.getAttribute('data-color'));
              });
            }
            data.value = colors;
          } else {
            data.value = value;
          }
        }
      };
      checkUndef(orderBy, el.target.dataset.orderby);
      checkUndef(color, el.target.dataset.color);
      checkUndef(order, el.target.dataset.order);
      checkUndef(size, el.target.dataset.size);
      checkUndef(priceMin, el.target.dataset.pricemin);
      checkUndef(priceMax, el.target.dataset.pricemax);
      var atributes = ['orderby', 'size', 'color', 'priceMin'];
      for (var i = 0; i < atributes.length; i++) {
        var selectedWrapper = document.querySelector('.selected-wrapper');
        var target = el.target.getAttribute("data-".concat(atributes[i]));
        var selectedFilter = document.createElement('p');
        selectedFilter.classList.add('selected');
        if (target !== null) {
          if (atributes[i] == 'orderby') {
            selectedFilter.innerHTML = "".concat(element.innerHTML);
            selectedFilter.id = atributes[i];
            if (document.querySelector('p#orderby')) {
              var _order = document.querySelector('p#orderby');
              _order.innerHTML = "".concat(element.innerHTML);
            } else {
              selectedWrapper.append(selectedFilter);
            }
          } else if (atributes[i] == 'size') {
            selectedFilter.innerHTML = "".concat(element.innerHTML);
            selectedFilter.id = atributes[i];
            if (!sizes.includes(element.innerHTML)) {
              sizes.push(element.innerHTML);
            } else {
              var param = sizes.indexOf(element.innerHTML);
              sizes.splice(param, 1);
            }
            if (document.querySelector('p#size')) {
              var _order2 = document.querySelector('p#size');
              if (sizes.length == 0) {
                _order2.remove();
              }
              _order2.innerHTML = sizes;
            } else {
              selectedWrapper.append(selectedFilter);
            }
            if (sizes.length == 0) {
              element;
            }
          } else if (atributes[i] == 'color') {
            selectedFilter.innerHTML = "".concat(element.innerHTML);
            selectedFilter.id = atributes[i];
            if (!colors.includes(element.innerHTML)) {
              colors.push(element.innerHTML);
            } else {
              var _param = colors.indexOf(element.innerHTML);
              colors.splice(_param, 1);
            }
            if (document.querySelector('p#color')) {
              var _order3 = document.querySelector('p#color');
              if (colors.length == 0) {
                _order3.remove();
              }
              _order3.innerHTML = colors;
            } else {
              selectedWrapper.append(selectedFilter);
            }
            if (colors.length == 0) {
              element;
            }
          } else if (atributes[i] == 'priceMin') {
            selectedFilter.innerHTML = "".concat(element.innerHTML);
            selectedFilter.id = atributes[i];
            if (document.querySelector('p#priceMin')) {
              var _order4 = document.querySelector('p#priceMin');
              _order4.innerHTML = "".concat(element.innerHTML);
            } else {
              selectedWrapper.append(selectedFilter);
            }
          }
          filtersQuantity.innerHTML = selectedWrapper.childElementCount + " selected";
        }
      }
      var activeFilters = getFilters();
      activeFilters.forEach(function (element) {
        element.addEventListener('click', function (el) {
          var form = document.querySelector('.form-hidden');
          var uncheckFilters = document.querySelectorAll("[data-".concat(el.target.id, "]"));
          uncheckFilters.forEach(function (element) {
            element.classList.remove('checked');
          });
          var selectedWrapper = document.querySelector('.selected-wrapper');
          form.querySelector("input[name=\"".concat(el.target.id)).value = '';
          if (el.target.id == 'priceMin') {
            document.querySelector('input[name=priceMax]').value = '';
          }
          if (el.target.id == 'orderby') {
            document.querySelector('input[name=orderby]').value = '';
          }
          el.target.remove();
          filtersQuantity.innerHTML = selectedWrapper.childElementCount + " selected";
        });
      });
    });
  });
  var getFilters = function getFilters() {
    var selectedFilters = document.querySelectorAll('.selected');
    return selectedFilters;
  };

  // передача в адресную строку

  window.addEventListener('load', function () {
    var urlParams = new URLSearchParams(window.location.search);
    var colorURL = urlParams.get('color');
    var sizeURL = urlParams.get('size');
    var orderURL = urlParams.get('order');
    var orderByURL = urlParams.get('orderby');
    var priceMinURL = urlParams.get('priceMin');
    var priceMaxURL = urlParams.get('priceMax');
    if (colorURL !== null && colorURL != "") {
      color.value = colorURL;
      var _colors = colorURL.split(',');
      if (_colors.length > 0) {
        _colors.forEach(function (element) {
          var chosedColor = document.querySelector("li[data-color=\"".concat(element, "\"]"));
          chosedColor.click();
        });
      }
    }
    if (sizeURL !== null && sizeURL != "") {
      size.value = sizeURL;
      var _sizes = sizeURL.split(',');
      if (_sizes.length > 0) {
        _sizes.forEach(function (element) {
          var chosedSize = document.querySelector("li[data-size=\"".concat(element, "\"]"));
          chosedSize.click();
        });
      }
    }
    if (orderURL !== null && orderByURL !== null) {
      order.value = orderURL;
      orderBy.value = orderByURL;
      var chosedOrder = document.querySelectorAll("li[data-orderby=\"".concat(orderByURL, "\"]"));
      if (chosedOrder.length > 0) {
        chosedOrder.forEach(function (element) {
          if (element.getAttribute('data-order') == orderURL) {
            element.click();
          }
        });
      }
    }
    if (priceMinURL !== null && priceMaxURL !== null) {
      priceMin.value = priceMinURL;
      priceMax.value = priceMaxURL;
      var chosedPrice = document.querySelectorAll("li[data-pricemin=\"".concat(priceMinURL, "\"]"));
      if (chosedPrice.length > 0) {
        chosedPrice.forEach(function (element) {
          if (element.getAttribute('data-pricemax') == priceMaxURL) {
            element.click();
          }
        });
      }
    }
  });
}
if (document.querySelector('.filters-apply')) {
  var filtersApply = document.querySelector('.filters-apply');
  var filtersReset = document.querySelector('.filters-reset');
  filtersApply.addEventListener('click', function () {
    var form = document.querySelector('.form-hidden');
    form.submit();
  });
  filtersReset.addEventListener('click', function () {
    var form = document.querySelector('.form-hidden');
    form.reset();
    var selectedFilters = document.querySelectorAll('.selected');
    selectedFilters.forEach(function (element) {
      element.click();
    });
  });
}
if (document.querySelector('.open-mobile-filter')) {
  var openMobileFilters = document.querySelector('.open-mobile-filter');
  openMobileFilters.addEventListener('click', function () {
    document.querySelector('.shop-filter').classList.toggle('opened');
  });
}

},{}],8:[function(require,module,exports){
"use strict";

document.addEventListener('click', function (e) {
  var target = e.target;
  if (target.closest('.category-heading')) {
    var category = target.closest('.category');
    if (category.classList.contains('opened')) {
      category.classList.remove('opened');
    } else {
      var categoryOp = document.querySelectorAll('.category.opened');
      if (categoryOp.length > 0) {
        categoryOp.forEach(function (element) {
          element.classList.remove('opened');
        });
      }
      category.classList.add('opened');
    }
  }
  if (!target.closest('.category') || target.classList.contains('category')) {
    var categoryOpened = document.querySelectorAll('.category.opened');
    categoryOpened.forEach(function (element) {
      element.classList.remove('opened');
      var categoryLists = document.querySelectorAll('.category-list');
      categoryLists.forEach(function (element) {
        element.classList.add('closed');
      });
    });
  }
  if (target.closest('.category-item') && !target.closest('.subcategory')) {
    var button = target.closest('.category-item');
    button.classList.toggle('opened');
    var activeButtons = document.querySelectorAll('.category-item.opened');
    if (activeButtons.length > 0) {
      activeButtons.forEach(function (element) {
        if (element !== button) {
          element.classList.remove('opened');
        }
      });
    }
  }
});

// mobile

var openMobileCatalog = document.querySelector('.nav-mobile-open');
var navShop = document.querySelector('.nav-shop');
var mobileCatalog = document.querySelector('.nav-shop__inner');
openMobileCatalog.addEventListener('click', function () {
  navShop.classList.add('opened');
  mobileCatalog.classList.add('opened');
});
var closeMobileCatalog = document.querySelector('.nav-mobile-close');
closeMobileCatalog.addEventListener('click', function () {
  navShop.classList.remove('opened');
  mobileCatalog.classList.remove('opened');
});

},{}],9:[function(require,module,exports){
"use strict";

if (document.querySelector('.popup')) {
  document.addEventListener('click', function (el) {
    if (el.target.classList.contains('open-popup')) {
      el.preventDefault();
      var popup = el.target.nextElementSibling;
      var popups = document.querySelectorAll('.popup');
      popups.forEach(function (element) {
        element.classList.remove('opened');
        document.body.classList.remove('opened');
      });
      popup.classList.add('opened');
      document.body.classList.add('opened');
      var close = popup.querySelector('.close');
      close.addEventListener('click', function (el) {
        el.preventDefault();
        popup.classList.remove('opened');
        document.body.classList.remove('opened');
      });
    }
  });
}
if (document.querySelector('.toggle')) {
  var toggles = document.querySelectorAll('.toggle');
  toggles.forEach(function (element) {
    var buttons = element.querySelectorAll('button');
    buttons.forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault();
        var parent = e.target.parentNode;
        buttons.forEach(function (button) {
          button.classList.remove('active');
        });
        el.classList.add('active');

        // check if the button is in the toggle-size container
        // if so, toggle between cm and inch grid
        // cm grid is created automatically from the inch grid
        if (parent.classList.contains('toggle--size')) {
          var popup = parent.closest('.popup');
          var grid = popup.querySelector('.grid');
          var gridCm = popup.querySelector('.grid--cm');
          switch (e.target.value) {
            case 'cm':
              grid.style.display = 'none';
              if (gridCm) {
                gridCm.style.display = 'grid';
              } else {
                createCMgrid(popup);
              }
              break;
            case 'inch':
              gridCm.style.display = 'none';
              grid.style.display = 'grid';
              break;
          }
        }
      });
    });
  });
}
var createCMgrid = function createCMgrid(popup) {
  var grid = popup.querySelector('.grid');
  var gridCm = document.createElement('div');
  gridCm.classList.add('grid', 'grid--cm');
  gridCm.innerHTML = grid.innerHTML;
  grid.parentNode.appendChild(gridCm);
  var cells = popup.querySelectorAll('.grid--cm > div');
  cells.forEach(function (cell) {
    var cellValue = parseInt(cell.innerHTML);

    // check if cell value is a number
    if (!isNaN(cellValue)) {
      // transform inch to cm
      cell.innerHTML = Math.round(cellValue * 2.54);
    }
  });
};

},{}],10:[function(require,module,exports){
"use strict";

if (document.querySelector('.close-mobile-search')) {
  var searchWrapper = document.querySelector('.search-wrapper-mobile');
  var closeBtn = document.querySelector('.close-mobile-search');
  var searchBtn = document.querySelector('.search-btn');
  closeBtn.addEventListener('click', function () {
    searchWrapper.classList.remove('opened');
  });
  if (window.innerWidth <= 768) {
    searchBtn.addEventListener('click', function (e) {
      if (!searchWrapper.classList.contains('opened')) {
        e.preventDefault();
        searchWrapper.classList.add('opened');
      }
    });
  }
}
// window.addEventListener('resize', function () {
//   location.reload();
// });

},{}],11:[function(require,module,exports){
"use strict";

window.addEventListener('load', function () {
  $('.card-slider').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    responsive: [{
      breakpoint: 991,
      settings: {
        swipe: false
      }
    }]
  });
  var cards = document.querySelectorAll('.card');
  cards.forEach(function (element) {
    var arrows = element.querySelectorAll('.slick-arrow');
    arrows.forEach(function (el) {
      el.addEventListener('click', function (event) {
        event.preventDefault();
      });
    });
  });
  $('.product__slider').slick({
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.image__preview',
    infinite: false,
    responsive: [{
      breakpoint: 991,
      settings: {
        arrows: false,
        dots: true
        // centerMode: true,
      }
    }]
  });
  $('.image__preview').slick({
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    arrows: false,
    asNavFor: '.product__slider',
    centerMode: false,
    infinite: false,
    focusOnSelect: true
  });
  function mobileSlider() {
    if (window.innerWidth < 991) {
      if (!$('.catalog__inner--featured').hasClass('slick-initialized')) {
        $('.catalog__inner').slick({
          speed: 300,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          infinite: false
        });
      }
    } else {
      if ($('.catalog__inner--featured').hasClass('slick-initialized')) {
        $('.catalog__inner').slick('unslick');
      }
    }
  }
  if ($('.catalog__inner--featured').length > 0) {
    mobileSlider();
    window.addEventListener('resize', function () {
      mobileSlider();
    });
  }
});

},{}],12:[function(require,module,exports){
"use strict";

if (document.querySelector('.tab')) {
  var tabsOpen = document.querySelectorAll('.tab-header');
  tabsOpen.forEach(function (tabHeader) {
    var tab = tabHeader.parentNode.nextElementSibling;
    if (tab) {
      tabHeader.addEventListener('click', function () {
        tabHeader.classList.toggle('opened');
        tab.classList.toggle("closed");
        // tabOpen.classList.toggle("opened")
      });
    }
  });
}

// if (document.querySelector('.tab-filter')) {
//     let tabFilters = document.querySelectorAll('.tab-filter')
//     tabFilters.forEach(tabFilter => {
//         let tabs = tabFilter.childNodes
//         tabs.forEach(element => {
//             element.addEventListener('click', () => {
//                 element.classList.add("active")
//             })
//         });
//     });
// }

},{}],13:[function(require,module,exports){
"use strict";

if (document.querySelector('.tabs-nav')) {
  var btns = document.querySelectorAll('.tabs-nav > button');
  // let tabs = document.querySelectorAll('.tabs > *');

  btns.forEach(function (btn) {
    btn.addEventListener('click', function (e) {
      e.preventDefault();
      if (e.target.classList.contains('active')) return;
      var tab = document.querySelector(".tabs > #".concat(e.target.dataset.tab));
      removeActive();
      e.target.classList.add('active');
      tab.classList.add('active');
    });
  });
  var removeActive = function removeActive() {
    var activeBtn = document.querySelector('.tabs-nav > button.active');
    var activeTab = document.querySelector('.tabs > .tab.active');
    if (activeBtn === null || activeTab === null) return;
    activeBtn.classList.remove('active');
    activeTab.classList.remove('active');
  };
}

},{}],14:[function(require,module,exports){
"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// add target blank to external links
var siteUrl = window.location.hostname;
var links = document.querySelectorAll('a[href*="//"]:not([href*="' + siteUrl + '"])');
var _iterator = _createForOfIteratorHelper(links),
  _step;
try {
  for (_iterator.s(); !(_step = _iterator.n()).done;) {
    var elem = _step.value;
    elem.setAttribute('target', '_blank');
    elem.setAttribute('rel', 'noopener noreferrer');
  }
} catch (err) {
  _iterator.e(err);
} finally {
  _iterator.f();
}

},{}],15:[function(require,module,exports){
"use strict";

if (document.querySelector('.temp-cards')) {
  var tempDiv = document.querySelector('.temp-cards');
  var topDiv = document.querySelector('.top');
  var botDiv = document.querySelector('.bottom');
  var tempLinks = tempDiv.querySelectorAll('a');
  var halfLength = Math.ceil(tempLinks.length / 2);
  if (tempLinks.length > 10) {
    for (var i = 0; i < tempLinks.length; i++) {
      if (i < halfLength) {
        topDiv.appendChild(tempLinks[i]);
      } else {
        botDiv.appendChild(tempLinks[i]);
      }
    }
  } else if (tempLinks.length == 0) {
    var noElements = document.createElement('p');
    noElements.style.fontSize = '22px';
    noElements.innerHTML = 'Sorry, No Products Matched Your Criteria';
    topDiv.appendChild(noElements);
  } else {
    for (var _i = 0; _i < tempLinks.length; _i++) {
      topDiv.appendChild(tempLinks[_i]);
    }
  }
}

},{}]},{},[1]);
